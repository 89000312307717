h1.times {
    font-size: 5rem;
    text-align: center;
}

a.times {
    color: black; 
    font-size:2rem;
    color: black
}

a.hidden.times {
    color: black; 
    font-size:1.3rem; 
    color: black;
}

div.row {
    width: 100%;
    margin: 0;
}

#header {
    background-color: white;
}
/* 
a.drop-down {
    font-size: 1rem;
} */

.my-navbar-item {
    text-align: center;
}

.brand {
    object-fit: contain;
    padding: 16px;
    padding-bottom: 0;
    margin-bottom: -20px;
    position: relative;
    min-width: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 300px;
}

@media screen and (min-width: 1200px) {
    .brand {
        position: absolute;
        max-width: 500px;
        min-width: 200px;
        max-height: 300px;
    }
}

.circle-icon {
    background-color: #305636;
    filter: invert(1);
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin: 2px;
}

.circle-icon img {
    height: 30px;
    margin-top: 10px;
}

#icon-section {
    width: min(1920px, 97%);
    margin-top: 4px;
    /* background: rgba(0, 0, 0, .2); */
    border-radius: 100px;
    padding: 5px;
    position: fixed;
    /* right: 0; */
    z-index: 9;
}