.title {
    font-family:'Times New Roman', Times, serif; font-size: 4rem; text-align: center;
}

.text-responsive {
    font-family:'Times New Roman', Times, serif;
}

.text-responsive-smaller {
    font-family:'Times New Roman', Times, serif;
}

.my-hobbies {
    font-family:'Times New Roman', Times, serif; font-size: 4rem; text-align: center;
}

.insta-row {
    height: 700px;
}

.about-me {
    background-color: #E4DEE6;
}

.text-start {
    text-align: start;
}

.resume-button {
    font-size: 1.2rem;
    width: 100%;
    letter-spacing: .3rem;
}