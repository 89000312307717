h1 {
    font-family:'Times New Roman', Times, serif; font-size: 4rem; text-align: center;
}

.cutoff {
    overflow: hidden;
}

.phone-bg {
    object-fit: cover;
}

.row-social-media {
    max-height: 600px;
    background-color: #373A3C;
}

#nmc-social-left {
    background-image: url('/public/img/social-media/NMC_Logo.png');
    background-repeat: no-repeat;
    /* background-color: #37424F; */
    background-size: contain;
    min-height: 300px;
    background-position: center;
    /* background-position-x: -200px; */
}

#therapute-social-left {
    background-image: url('/public/img/social-media/Therapute_Logo.png');
    background-repeat: no-repeat;
    /* background-color: #37424F; */
    background-size: contain;
    min-height: 300px;
    background-position: center;
    /* background-position-x: -200px; */
}

#cfa-social-left {
    background-image: url('/public/img/social-media/CFA_Logo.png');
    background-repeat: no-repeat;
    /* background-color: #37424F; */
    background-size: contain;
    min-height: 300px;
    background-position: center;
    /* background-position-x: -200px; */
}

#nmc-social-right {
    background-image: url('/public/img/social-media/NMC_Background.png');
    background-repeat: no-repeat;
    background-color: #444951;
    background-size: cover;
    /* background-position-x: -200px; */
}

#cfa-social-right {
    background-image: url('/public/img/social-media/CFA_Background.png');
    background-repeat: no-repeat;
    background-color: #444951;
    background-size: cover;
    /* background-position-x: -200px; */
}

.white-text a {
    /* color: white; */
    text-decoration: none;
    color: white;
}

.nmc-sm {
    display: flex;
    flex-direction: column;
}

#therapute-social {
    background-image: url('/public/img/social-media/Therapute_Background.png');
    background-repeat: no-repeat;
    background-color: #4C7898;
    background-size: cover;
    background-position: right;
}

#after-grad {
    height: 650px;
    background-image: url('/public/img/social-media/socialmedia.png');
    background-repeat: no-repeat;
    background-color: #BD9BB8;
    background-position: center;
}

.bit-spaced {
    font-size: 1.5rem;
}

.spaced {
    font-size: 1.5rem;
}

.carousel-inner {
    height: auto;
}

.carouselExampleSlidesOnly1 {
    --height: 300px;
}

.social-media-img img {
    width: inherit !important;
}

.social-media-img {
    margin: 0;
    padding: 0;
}

.leslee-img img {
    width: inherit !important;
}

.leslee-img {
    margin: 0;
    padding: 0;
}

@media (max-width: 1450px) {
    .image-container .image-text.col-xl-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .my-navbar-item {
        text-align: center;
    }

    .my-navbar-item a{
        font-size: 3rem;
    }

    .text-responsive{
        font-size: 2rem !important;
    }

    .text-responsive-smaller{
        font-size: 2rem !important;
    }

    .text-responsive-bigger {
        font-size: 3rem !important;
    }
}


@media (min-width: 1200px) {
    .welcome-text-social-media {
        font-size: 1.1rem;
        position: relative;
        /* top: max(-600px, -30vw); */
        z-index: 1;
        color: white;
    }

    .welcome-social-media {
        max-height: 40.5vw;
    }

    .my-navbar-item {
        text-align: center;
    }

    .my-navbar-item a{
        font-size: 2rem;
    }

    .projects-item {
        /* width: fit-content; */
        position: relative;
    }

    /* todo what does this do? */
    .phone-bg {
        max-height: 600px;
        width: 100%;
    }

    .nmc-bg {
        max-height: 600px;
        width: 100%;
    }
    
    
    .container .works iframe {
        width: 100%;
    }

  
}


@media (max-width: 1200px) {

    .image-text img {
        background-image: none;
    }

    #nmc-social {
        background-image: none;
    }

    #after-grad {
        height: auto;
        background-image: none;
    }
}

@media (max-width: 600px) {
    #nmc-imgs {
        height: auto;
    }
}