body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face { font-family: cocogoose; src: url('/public/fonts/cocogoose/Cocogoose Pro Ultralight-trial.ttf'); } 
@font-face { font-family: claudia; src: url('/public/fonts/claudia script.ttf'); } 
@font-face { font-family: cormorant; src: url('/public/fonts/cormorant-bold.ttf'); } 


body {
    background-color: #E4DEE6;
    /* background-color: #FAE2CE; */
    /* animation: fadein 1s 1; */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#wrapper {
    max-width: 1920px;
    margin: auto;
    #home {
        /* background-color: #373A3C; */
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
  }

.fade-top {
    animation: fadeintop 1s 1;
}


@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeintop {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.jumbotron {
    background-color: whitesmoke;
    margin-top: 2%;
}

#logo {
    opacity: 0;
}

hr.style8 {
	border-top: 1px solid black;
    width: 50%;
}
.container {
    max-width: 90%;
    /* background-color: #FAE2CE; */
}

.dot {
    display: inline;
    margin-left: 1em;
    content: "\00B7";
    color: #bbb;
}

.cocogoose {
    font-family: 'cocogoose';
}

#leslee {
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 200vw;
    position: relative;
    object-fit: contain;
}

/* .row {
    margin: auto;
    padding: 0;
} */

#works {
    min-height: 20vh;
}

img {
    height: auto;
}

.work-row {
    margin: 1% 0 1% 0
}

.times {
    font-family: 'Times New Roman', Times, serif;
}

.main-font {
    font-size: 4rem;
    font-family: 'Times New Roman', Times, serif;
}

.home-cont {
    background-color: #373A3C;
    height: auto;
}

.text-block {
    padding: 8px;
}

.text-block p {
    padding: 32px;
    padding-top: 1rem;
    
}

.text-cent {
    text-align: center;
}

.text-start {
    text-align: start;
}

.pink-bg {
    background-color: #CFA9C9;
}

.grey-bg {
    background-color: #E4DEE6;
}

.bold {
    font-weight: bold;
}

.leslee-button {
    background: #88778D;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 2rem;
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.contact-me-button {
    width: 50%;
}

.spaced {
    letter-spacing: .1rem;
}

.bit-spaced {
    letter-spacing: .3rem;
}

.kinda-spaced {
    letter-spacing: .5rem;
}

.very-spaced {
    letter-spacing: 1rem;
}

.read-letter-button {
    width: 100%;
    margin: auto;
    padding: 0;
    font-size: 1rem;
    background-color: white;
    color: black;
    font-weight: bold;
}

.flex-reverse {
    flex-direction: row-reverse;
}

.image-container {
    position: relative;
    text-align: start;
    color: white;
    background-image: url("/public/img/social-media/phone-bg.png");
    background-repeat: no-repeat;
    background-color: #907D88;
    background-size: cover;
}

#keith {
    background-image: url("/public/img/home/wordsbg.jpg");
}

.keith-container {
    width: 100%;
    margin: auto;
    display: flex;
    margin-top: -50px
}

p.desc {
    font-size: 1rem;
    margin-bottom: 0;
    padding: 0;
}

#keith img {
    width: 200px;
    height: 200px;
    margin: auto;
}

#keith {
    background-image: url("/public/img/home/wordsbg.jpg");
}

.bev-container {
    width: 100%;
    margin: auto;
    display: flex;
    margin-top: -30px
}

#bev img {
    width: 200px;
    height: 200px;
    margin: auto;
}

.testimonials {
    color: black;
    font-family: 'Times New Roman', Times, serif;

    font-size: 1.5rem;
    h2 {
        font-size: 3rem;
    }
}

@media (min-width: 1450px) {
    .text-responsive {
        font-size: 2rem !important;
    }

    .text-responsive-smaller {
        font-size: 1.5rem !important;
    }

    .text-responsive-smallest {
        font-size: 1rem !important;
    }

    .text-responsive-bigger {
        font-size: 3rem;
    }

    #home {
            
        .welcome-leslee {
            max-height: 660px;
        }

        .my-navbar-item {
            text-align: center;
        }

        .my-navbar-item a{
            font-size: 2rem;
        }


        .projects-item {
            /* width: fit-content; */
            position: relative;
        }

        /* todo what does this do? */
        .projects-img {
            max-height: 500px;
            width: 100%;
        }
        
        .container .works iframe {
            width: 100%;
        }

        .welcome-text {
            font-size: 2rem;
            position: relative;
            top: max(-700px, -37vw);
            /* top: -100px; */
            z-index: 2;
            color: white;
            
            .welcome-text-header {
                text-align: center;
                font-weight: 500;
            }

            .welcome-text-g {
                font-weight: 0 !important;
            }
            
            .welcome-text-p {
                font-weight: 500;
            }
        }
        
    }
}


@media (max-width: 1450px) {
    #home {
        .projects-img {
            width: 100%;
            max-width: 200vw;
            object-fit: cover;
            position: relative;
            /* transform: scale(2, 2); */
            height: 700px;
        }
        
        .welcome-text {
            font-size: 1.2rem;
            position: relative;
            z-index: 2;
            margin-top: -500px;
            color: white;
            /* height: auto;
            background-color: #373A3C; */
            
            .welcome-text-header {
                text-align: center;
                font-weight: 500;
            }
            
            .welcome-text-g {
                font-weight: 0 !important;
            }
            
            .welcome-text-p {
                font-size: 1.2rem;
                font-weight: 500;
            }

            &.col-xl-6 {
                width: 100%;
                max-width: 100% !important;
                flex: 0 0 100%;
            }
        }

        .testimonials {
            position: relative;
        }

        .leslee-button {
            width: 50%;
        }
    }
}

@media (min-width: 576px) and (max-width: 1450px) {
    /* .text-responsive{
        font-size: 3rem !important;
    }
    
    .text-responsive-smaller{
        font-size: 2rem !important;
    }
    
    .text-responsive-bigger {
        font-size: 4.5rem !important;
    } */
    #home {

        .my-navbar-item {
            text-align: center;
        }
        
        .my-navbar-item a{
            font-size: 3rem;
        }
        
    }
}

@media (max-width: 992px) {
    .container {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    #works {
        padding: 0;
        max-width: 100vw;
        width: 100vw;
    }

    .container .works {
        padding: 0;
        max-width: 100vw;
        width: 100vw;
    }

    .container .works iframe {
        max-width: 560px;
        width: 90vw;
    }
}

.icon img {
    height: 2rem;
}

.text-align-center {
    text-align: center;
}

.work {
    margin: 1vh;
}

.hidden {
    /* display: none; */
    margin: 0;
    opacity: 0;
    transition: opacity 1s;
}

.claudia {
    font-family: 'claudia';
}


.fade {
    opacity: 1;
}

.fade-top {
    opacity: 1;
}

.cormorant {
    font-family: cormorant;
    letter-spacing: 10px;
}