p {
    font-family: 'Times New Roman', Times, serif;
}

#videos .frame{
    display: flex; 
    justify-content: center;
    align-items: center;
}

#videos div iframe {
    height: 50vw;
    max-height: 500px;
    max-width: 1000px;
}